<template>
    <div class="main-container">
        <!-- <h5 class="pageTitle" v-html="pageTitle"></h5> -->
        <script type="application/javascript" src="https://bossanova.uk/jspreadsheet/v4/jexcel.js"></script>
        <script type="application/javascript" src="https://jsuites.net/v4/jsuites.js"></script>
        <link rel="stylesheet" href="https://bossanova.uk/jspreadsheet/v4/jexcel.css" type="text/css" />
        <link rel="stylesheet" href="https://jsuites.net/v4/jsuites.css" type="text/css" />

        <div>
            <div id="spreadsheet"></div>

            <mdb-container class="text-right">
                <mdb-btn id="backBtn" @click="moveBack" v-if="showButtonBack">목록</mdb-btn>
                <mdb-btn id="issueBtn" outline="primary" @click="saveCertificate" v-if="showButton1" :disabled="disableButton1">발급</mdb-btn>
                <mdb-btn @click="makeExcel" v-if="showButton2" :disabled="disableButton2">다운로드</mdb-btn>
            </mdb-container>
        </div>        
        <mdb-modal size="lg" :show="dronInfoModal" @close="closeDronInfoModal">
            <mdb-modal-header>
                <mdb-modal-title>초경량비행장치 (Ultra-light Vehicle)</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-datatable-2
                    v-model="droneInfoList"
                    class="text-center drone-info-modal"
                    striped
                    bordered
                    hover
                    noFoundMessage="데이터가 없습니다."/>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="secondary" @click.native="closeDronInfoModal">Close</mdb-btn>                
            </mdb-modal-footer>
        </mdb-modal>

        <mdb-modal size="lg" :show="airFieldModal" @close="closeAirFieldModal">
            <mdb-modal-header>
                <mdb-modal-title>비행장소 (An Airfield)</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-datatable-2
                    v-model="airFieldList"
                    class="text-center"
                    striped
                    bordered
                    hover
                    noFoundMessage="데이터가 없습니다."/>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="secondary" @click.native="closeAirFieldModal">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>

        <mdb-modal size="lg" :show="instructorModal" @close="closeInstructorModal">
            <mdb-modal-header>
                <mdb-modal-title>지도조종자 (Instructor)</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-datatable-2
                    v-model="instructorList"
                    class="text-center"
                    striped
                    bordered
                    hover
                    noFoundMessage="데이터가 없습니다."/>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="secondary" @click.native="closeInstructorModal">Close</mdb-btn>                
            </mdb-modal-footer>
        </mdb-modal>

        <mdb-modal size="lg" :show="purposeModal" @close="closePurposeModal">
            <mdb-modal-header>
                <mdb-modal-title>비행목적 (Purpose of Flight) (훈련내용) (Contents of Training)</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <!-- 전체 선택/해제 버튼 -->
                <div>
                    <input type="checkbox" id="selectAll" v-model="checkSelectAll" @change="selectAllCheckboxes" class="custom-checkbox"/>
                    <label for="selectAll" class="checkbox-label">
                        <span class="select-all-text">전체 선택</span>
                    </label>
                </div>

                <div class="checkbox-container" v-for="(checkbox, index) in checkboxes" :key="index">
                    <input type="checkbox" :id="'checkbox' + index" v-model="checkbox.value" class="custom-checkbox"/>                    
                    <label :for="'checkbox' + index" class="checkbox-label">{{ checkbox.label }}</label>
                </div>                
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="secondary" @click.native="closePurposeModal">Close</mdb-btn>
                <mdb-btn color="primary" @click.native="getCheckedValues">Apply</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <div class="progress-container">
            <Progress :isProgress="isProgress"/>
        </div>
    </div>
</template>

<style>
.checkbox-container {
  display: inline-block; /* 각 체크박스를 가로로 배치 */
  margin-right: 15px; /* 체크박스 간 간격 설정 */
}
.checkbox-label {
  margin-left: 5px; /* 체크박스와 라벨 사이의 간격 설정 */  
  color: #333; /* 텍스트 색상 설정 */
}

.custom-checkbox:checked,
.custom-checkbox:not(:checked) {
  position: static;
  pointer-events: auto;
  opacity: 1;
}

.modal-lg {
    max-width: 1000px;
}

.modal-cover {
    background: rgba(0,0,0,.5);
    position: fixed;
    z-index: 48;
}

.progress-container {
  position: fixed;
  top: 50%;
  left: 50%;  
}

.progress-icon {    
    position: fixed;
    z-index: 49;
}

.select-all-text {
    font-size: 18px;
    font-weight: bold;
}

.drone-info-modal table th {
    font-size: .8rem;
}

.drone-info-modal table td {
    font-size: .8rem;
}


</style>

<script>
import jspreadsheet from 'jspreadsheet-ce';
import exceljs from 'exceljs';
import axios from "@/axios";
import { mdbContainer, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbDatatable2 } from "mdbvue";
import Progress from "@/components/Progress";

import drone_info_list from "@/assets/data/columns/drone_info_list";
import airfield_list from "@/assets/data/columns/airfield_list";
import instructor_list from "@/assets/data/columns/instructor_list";


export default {
    name: "회원 테스트",
    components: {
        mdbContainer,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn,
        mdbDatatable2,
        Progress
    },    
    data() {
        return {
            constants: {
                TOP_LINE: 2,
                LEFT_LINE: 4,
                RIGHT_LINE: 8,
                BOTTOM_LINE: 16,
                ALL_LINE: 30
            },
            showButtonBack: true,
            showButton1: true,
            showButton2: false,
            disableButtonBack: true,
            disableButton1: true,
            disableButton2: true,
            pageTitle: '회원 테스트',
            droneInfoList: {
                columns: drone_info_list,
                rows: []
            },
            airFieldList: {
                columns: airfield_list,
                rows: []
            },
            instructorList: {
                columns: instructor_list,
                rows: []
            },
            checkboxes: [],
            checkSelectAll: false,
            checkedValues: '',
            issue_date: '',

            userInfo: this.$route.params.userInfo,
            certificateInfo: this.$route.params.certificateInfo,
            source: this.$route.params.source,
            
            certificateList: [],
            flightList: [],
            organizationInfo: [],
            droneInfo: [],
            airField: [],
            flightPurpose: [],
            instructor: [],
            isChecked: 'false',
            pageMaxRow: 12,
            pageMaxCol: 18,
            mySpreadsheet: null,
            dronInfoModal: false,
            dronInfoModalIndex: 0,
            airFieldModal: false,
            airFieldModalIndex: 0,
            instructorModal: false,
            instructorModalIndex: 0,
            purposeModal: false,
            selectedRow: null,
            selectedCol: null,
            workbook: null,
            sheet: null,
            isModal: false,
            isProgress: false
        }
    },
    created() {
        window.droneInfoSelect = this.droneInfoSelect;
        window.airFieldSelect = this.airFieldSelect;
        window.instructorSelect = this.instructorSelect;
    },
    mounted() {
        this.init();        
    },
    methods: {
        async init() {
            const asyncFunctions = [this.getOrganizationInfo(), this.getDroneInfo(), this.getAirFieldInfo(), this.getFlightPurpose(), this.getInstructorInfo()];
            
            // 선택 입력사항 레코드를 먼저 받아 온 후 스프레드시트 셋팅
            try {
                await Promise.all(asyncFunctions);                

            } catch (error) {
                console.error('에러 발생: ' + error);
            }            

            if (this.source === "issue") {                
                this.getIssueUserInfo();              
            } else if (this.source === "reissue") {                
                this.getReIssueCertificate();
                this.showButton1 = false;
                this.showButton2 = true;
            } else {
                this.setJSpreadsheet();
            }            
        },
        formatDate(date) {
            const year = date.getFullYear() % 100;
            const month = ('0' + (date.getMonth()+1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);

            return `${year}.${month}.${day}`;
        },
        getIssueUserInfo() {
            this.isProgress = true;
            const self = this;

            const config = {
                method: 'get',
                url: `/certificate/create?id=${self.userInfo.id}`,
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            axios(config)
                .then((res) => {                    
                    this.flightList = res.data;

                    // flight_date Sort
                    this.flightList.sort((a, b) => {
                        if (a.flight_date < b.flight_date) return -1;
                        if (a.flight_date > b.flight_date) return 1;
                        return 0;
                    });

                    if(this.flightList.length > 12) {
                        // 스프레드시트 셋팅 후 비행기록 셋팅
                        this.setIssueJSpreadsheet(this.flightList.length);                   
                    } else {
                        // 스프레드시트 셋팅 후 비행기록 셋팅
                        this.setJSpreadsheet();
                    }

                    this.mySpreadsheet.setStyle({ A2:'font-size: 20px' });

                    this.issue_date = new Date();
                    var formattedIssueDate = this.formatDate(this.issue_date);
                    var issue_info = this.organizationInfo[0].organization + ' / ' + this.organizationInfo[0].address; 
                    this.mySpreadsheet.setValueFromCoords(2, 3, this.userInfo.name, true);                    
                    this.mySpreadsheet.setValueFromCoords(6, 3, this.userInfo.organization, true);
                    this.mySpreadsheet.setValueFromCoords(16, 3, this.userInfo.phone, true);
                    this.mySpreadsheet.setStyle('C4','background-color','#fffacd');
                    this.mySpreadsheet.setStyle('G4','background-color','#fffacd');
                    this.mySpreadsheet.setStyle('M4','background-color','#fffacd');
                    this.mySpreadsheet.setStyle('Q4','background-color','#fffacd');

                    if(this.flightList.length > 12) {
                        
                        this.mySpreadsheet.setValueFromCoords(3,  this.flightList.length + 14, formattedIssueDate, true);
                        this.mySpreadsheet.setReadOnly([3,  this.flightList.length + 14], true);
                        this.mySpreadsheet.setValueFromCoords(12, this.flightList.length + 14, issue_info, true);
                        this.mySpreadsheet.setReadOnly([12, this.flightList.length + 14], true);
                        this.mySpreadsheet.setValueFromCoords(3,  this.flightList.length + 15, this.organizationInfo[0].officer, true);
                        this.mySpreadsheet.setReadOnly([3,  this.flightList.length + 15], true);
                        this.mySpreadsheet.setValueFromCoords(10, this.flightList.length + 15, this.organizationInfo[0].phone, true);
                        this.mySpreadsheet.setReadOnly([10, this.flightList.length + 15], true);
                    } else {        
                        this.mySpreadsheet.setValueFromCoords(3, 26, formattedIssueDate, true);
                        this.mySpreadsheet.setReadOnly([3, 26], true);
                        this.mySpreadsheet.setValueFromCoords(12, 26, issue_info, true);
                        this.mySpreadsheet.setReadOnly([12, 26], true);
                        this.mySpreadsheet.setValueFromCoords(3, 27, this.organizationInfo[0].officer, true);
                        this.mySpreadsheet.setReadOnly([3, 27], true);
                        this.mySpreadsheet.setValueFromCoords(10, 27, this.organizationInfo[0].phone, true);
                        this.mySpreadsheet.setReadOnly([10, 27], true);
                    }

                    // 비행기록이 있을 경우만
                    if(this.flightList.length > 0)
                    {

                        // 웹에서 보여줄 때는 페이지 구분 없이 나열. 엑셀 파일에서만 시트 나누는 것으로.                    
                        let row = 11;                       
                        for(let index = 0; index < this.flightList.length; index++)
                        {   
                            var dateObject = new Date(this.flightList[index].flight_date);
                            var formattedDate = this.formatDate(dateObject);
                            
                            this.mySpreadsheet.setValueFromCoords(0, row, formattedDate, true);
                            this.mySpreadsheet.setReadOnly([0, row], true);                          
                            this.mySpreadsheet.setValueFromCoords(1, row, Number(this.flightList[index].flight_count), true);
                            this.mySpreadsheet.setReadOnly([1, row], true);                            
                            this.mySpreadsheet.setValueFromCoords(10, row, Number(this.flightList[index].solo_time), true);
                            this.mySpreadsheet.setReadOnly([10, row], true);                             
                            this.mySpreadsheet.setValueFromCoords(11, row, Number(this.flightList[index].training_time), true);
                            this.mySpreadsheet.setReadOnly([11, row], true);
                            this.mySpreadsheet.setValueFromCoords(12, row, Number(this.flightList[index].trainer_time), true);
                            this.mySpreadsheet.setReadOnly([12, row], true); 
                            this.mySpreadsheet.setReadOnly([9, row], true);
                            this.mySpreadsheet.setReadOnly([13, row], true);
                            
                            this.mySpreadsheet.setStyle('C'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('D'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('E'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('F'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('G'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('H'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('I'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('O'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('P'+(row+1).toString(),'background-color','#fffacd');
                            this.mySpreadsheet.setStyle('Q'+(row+1).toString(),'background-color','#fffacd');
                            
                            row++;
                            
                        }                        

                        //이하여백 문구 추가 + 0 제거
                        //데이터 없는 자동계산 삭제
                        if(this.flightList.length < this.pageMaxRow){                    

                            for(row; row < this.pageMaxRow + 11; row++)
                            {
                                this.mySpreadsheet.setValueFromCoords(9, row, '', true);
                                this.mySpreadsheet.setValueFromCoords(13, row, '', true);

                                for(let col=0; col < this.pageMaxCol; col++) {                                    
                                    this.mySpreadsheet.setReadOnly([col, row], true);
                                }
                            }                            
                        } 
                        

                        this.getIssueRecentData();


                    } else if(this.flightList.length === 0) {

                        let row = 11;       
                        for(row; row < this.pageMaxRow + 11; row++)
                        {
                            this.mySpreadsheet.setValueFromCoords(9, row, '', true);
                            this.mySpreadsheet.setValueFromCoords(13, row, '', true);

                            for(let col=0; col < this.pageMaxCol; col++) {                                    
                                this.mySpreadsheet.setReadOnly([col, row], true);
                            }
                        }            
                        
                        this.mySpreadsheet.setReadOnly([3, 26], true);                        
                        this.mySpreadsheet.setReadOnly([12, 26], true);                        
                        this.mySpreadsheet.setReadOnly([3, 27], true);                        
                        this.mySpreadsheet.setReadOnly([10, 27], true);

                        this.disableButton1 = true;
                        this.isProgress = false;
                    }                    
                    

                })
        },
        async getReIssueCertificate() {
            this.isProgress = true;
            const config = {
                method: 'get',
                url: `/certificate/detail?no=${this.certificateInfo.uuid}`,
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            await axios(config)
                .then((res) => {
                    this.certificateList = res.data;
                })

                if(this.certificateList.records.length > 12) {
                    // 스프레드시트 셋팅 후 비행기록 셋팅
                    this.setIssueJSpreadsheet(this.certificateList.records.length);                   
                } else {
                    // 스프레드시트 셋팅 후 비행기록 셋팅
                    this.setJSpreadsheet();
                }

            this.mySpreadsheet.setStyle({ A2:'font-size: 20px' });

            this.mySpreadsheet.setValueFromCoords(1, 0, this.certificateList.issuingNo, true);
            this.mySpreadsheet.setValueFromCoords(2, 3, this.certificateList.userName, true);
            this.mySpreadsheet.setValueFromCoords(6, 3, this.certificateList.userCompany, true);
            this.mySpreadsheet.setValueFromCoords(12, 3, this.certificateList.identifyNo, true);
            this.mySpreadsheet.setValueFromCoords(16, 3, this.certificateList.userPhone, true);
            
            this.issue_date = this.certificateList.issuingDate;
            
            var issue_info = this.certificateList.issuingOrganization + ' / ' + this.certificateList.issuingAddress;    
            
            let readonlyRow = 29;

            if(this.certificateList.records.length > 12) {
                this.mySpreadsheet.setValueFromCoords(3,  this.certificateList.records.length + 14, this.formatDate(new Date(this.certificateList.issuingDate)), true);
                this.mySpreadsheet.setValueFromCoords(12, this.certificateList.records.length + 14, issue_info, true);
                this.mySpreadsheet.setValueFromCoords(3,  this.certificateList.records.length + 15, this.certificateList.issuingOfficer, true);
                this.mySpreadsheet.setValueFromCoords(10, this.certificateList.records.length + 15, this.certificateList.issuingPhone, true);
                readonlyRow = this.certificateList.records.length + 17;
            } else {
                this.mySpreadsheet.setValueFromCoords(3, 26, this.formatDate(new Date(this.certificateList.issuingDate)), true);
                this.mySpreadsheet.setValueFromCoords(12, 26, issue_info, true);
                this.mySpreadsheet.setValueFromCoords(3, 27, this.certificateList.issuingOfficer, true);
                this.mySpreadsheet.setValueFromCoords(10, 27, this.certificateList.issuingPhone, true);
            }

            // flightDate Sort
            this.certificateList.records.sort((a, b) => {
                if (a.flightDate < b.flightDate) return -1;
                if (a.flightDate > b.flightDate) return 1;

                return 0;
            });

            let row = 11;
            this.certificateList.records.forEach((data)=> {
                this.mySpreadsheet.setValueFromCoords(0, row, this.formatDate(new Date(data.flightDate)), true);
                this.mySpreadsheet.setValueFromCoords(1, row, data.flightCount, true);
                this.mySpreadsheet.setValueFromCoords(2, row, data.droneCategory, true);
                this.mySpreadsheet.setValueFromCoords(3, row, data.droneType, true);
                this.mySpreadsheet.setValueFromCoords(4, row, data.droneNo, true);
                this.mySpreadsheet.setValueFromCoords(5, row, data.droneDate, true);
                this.mySpreadsheet.setValueFromCoords(6, row, data.droneWeight, true);
                this.mySpreadsheet.setValueFromCoords(7, row, data.droneMtow, true);
                this.mySpreadsheet.setValueFromCoords(8, row, data.airField, true);
                this.mySpreadsheet.setValueFromCoords(10, row, data.soloTime, true);
                this.mySpreadsheet.setValueFromCoords(11, row, data.trainingTime, true);
                this.mySpreadsheet.setValueFromCoords(12, row, data.trainerTime, true);
                this.mySpreadsheet.setValueFromCoords(14, row, data.flightPurpose, true);
                this.mySpreadsheet.setValueFromCoords(15, row, data.instructorName, true);
                this.mySpreadsheet.setValueFromCoords(16, row, data.instructorNo, true);
                this.mySpreadsheet.setValueFromCoords(17, row, data.instructorSign, true);
                row++;
            });

            const textRow = row;

            //이하여백 문구 추가 + 0 제거
            //데이터 없는 자동계산 삭제
            if(this.certificateList.records.length < this.pageMaxRow){                    

                for(row; row < this.pageMaxRow + 11; row++)
                {
                    this.mySpreadsheet.setValueFromCoords(9, row, '', true);
                    this.mySpreadsheet.setValueFromCoords(13, row, '', true);
                }

                this.mySpreadsheet.setValueFromCoords(8, textRow, '-', true);
                this.mySpreadsheet.setValueFromCoords(9, textRow, '이', true);
                this.mySpreadsheet.setValueFromCoords(10, textRow, '하', true);
                this.mySpreadsheet.setValueFromCoords(11, textRow, '여', true);
                this.mySpreadsheet.setValueFromCoords(12, textRow, '백', true);
                this.mySpreadsheet.setValueFromCoords(13, textRow, '-', true);
            }

            for (var i=0; i < 18; i++) {
                for (var j=0; j < readonlyRow; j++) {
                    this.mySpreadsheet.setReadOnly([i,j], true);
                }
            }

            this.disableButton2 = false;
            this.isProgress = false;
        },        
        async getCertificate() {

            const config = {
                method: 'get',
                url: `/certificate/detail?no=${this.certificateInfo.uuid}`,
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            await axios(config)
                .then((res) => {
                    this.certificateList = res.data;
                })

            this.mySpreadsheet.setValueFromCoords(1, 0, this.certificateList.issuingNo, true);
            this.mySpreadsheet.setValueFromCoords(2, 3, this.certificateList.userName, true);
            this.mySpreadsheet.setValueFromCoords(6, 3, this.certificateList.userCompany, true);
            this.mySpreadsheet.setValueFromCoords(12, 3, this.certificateList.identifyNo, true);
            this.mySpreadsheet.setValueFromCoords(16, 3, this.certificateList.userPhone, true);
            
            this.issue_date = this.certificateList.issuingDate;
            this.mySpreadsheet.setValueFromCoords(3, 26, this.formatDate(new Date(this.certificateList.issuingDate)), true);
            
            var issue_info = this.certificateList.issuingOrganization + ' / ' + this.certificateList.issuingAddress;            

            this.mySpreadsheet.setValueFromCoords(12, 26, issue_info, true);
            this.mySpreadsheet.setValueFromCoords(3, 27, this.certificateList.issuingOfficer, true);
            this.mySpreadsheet.setValueFromCoords(10, 27, this.certificateList.issuingPhone, true);

            let row = 11;
            this.certificateList.records.forEach((data)=> {
                this.mySpreadsheet.setValueFromCoords(0, row, this.formatDate(new Date(data.flightDate)), true);
                this.mySpreadsheet.setValueFromCoords(1, row, data.flightCount, true);
                this.mySpreadsheet.setValueFromCoords(2, row, data.droneCategory, true);
                this.mySpreadsheet.setValueFromCoords(3, row, data.droneType, true);
                this.mySpreadsheet.setValueFromCoords(4, row, data.droneNo, true);
                this.mySpreadsheet.setValueFromCoords(5, row, data.droneDate, true);
                this.mySpreadsheet.setValueFromCoords(6, row, data.droneWeight, true);
                this.mySpreadsheet.setValueFromCoords(7, row, data.droneMtow, true);
                this.mySpreadsheet.setValueFromCoords(8, row, data.airField, true);
                this.mySpreadsheet.setValueFromCoords(10, row, data.soloTime, true);
                this.mySpreadsheet.setValueFromCoords(11, row, data.trainingTime, true);
                this.mySpreadsheet.setValueFromCoords(12, row, data.trainerTime, true);
                this.mySpreadsheet.setValueFromCoords(14, row, data.flightPurpose, true);
                this.mySpreadsheet.setValueFromCoords(15, row, data.instructorName, true);
                this.mySpreadsheet.setValueFromCoords(16, row, data.instructorNo, true);
                this.mySpreadsheet.setValueFromCoords(17, row, data.instructorSign, true);
                row++;
            });

            const textRow = row;

            //이하여백 문구 추가 + 0 제거
            //데이터 없는 자동계산 삭제
            if(this.certificateList.records.length < this.pageMaxRow){                    

                for(row; row < this.pageMaxRow + 11; row++)
                {
                    this.mySpreadsheet.setValueFromCoords(9, row, '', true);
                    this.mySpreadsheet.setValueFromCoords(13, row, '', true);
                }

                this.mySpreadsheet.setValueFromCoords(8, textRow, '-', true);
                this.mySpreadsheet.setValueFromCoords(9, textRow, '이', true);
                this.mySpreadsheet.setValueFromCoords(10, textRow, '하', true);
                this.mySpreadsheet.setValueFromCoords(11, textRow, '여', true);
                this.mySpreadsheet.setValueFromCoords(12, textRow, '백', true);
                this.mySpreadsheet.setValueFromCoords(13, textRow, '-', true);
            }          
            
            this.disableButton2 = false;
        },        
        getIssueRecentData() {
            const self = this;

            const config = {
                method: 'get',
                url: `/certificate/recent?id=${self.userInfo.uuid}`,
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            
            axios(config)
                .then((res) => {
                    
                    //데이터 없을 경우 ''의 빈값으로 들어옴  
                    if(res.data !== '') {                  
                    
                        this.certificateList = res.data;
                        

                        if (this.certificateList.length !== 0) {

                            //이름, 소속, 생년월일/여권번호, 연락처
                            this.mySpreadsheet.setValueFromCoords(2, 3, this.certificateList.userName, true);                            
                            this.mySpreadsheet.setValueFromCoords(6, 3, this.certificateList.userCompany, true);
                            this.mySpreadsheet.setValueFromCoords(12, 3, this.certificateList.identifyNo, true);
                            this.mySpreadsheet.setValueFromCoords(16, 3, this.certificateList.userPhone, true);

                            if(this.certificateList.records.length !== 0)
                            {                    
                                                
                                for(let index = 0; index < this.certificateList.records.length; index++)
                                {   
                                    var dateObject = new Date(this.certificateList.records[index].flightDate);
                                    var formattedDate = this.formatDate(dateObject);
                                    var row = 0;

                                    for(let flightIndex = 0; flightIndex < this.flightList.length; flightIndex++) 
                                    {   
                                        row = flightIndex + 11;
                                        if (this.mySpreadsheet.getValueFromCoords(0, row) === formattedDate) {
                                            //set 선택데이터
                                            this.mySpreadsheet.setValueFromCoords(2, row, this.certificateList.records[index].droneCategory, true);
                                            this.mySpreadsheet.setValueFromCoords(3, row, this.certificateList.records[index].droneType, true);
                                            this.mySpreadsheet.setValueFromCoords(4, row, this.certificateList.records[index].droneNo, true);
                                            this.mySpreadsheet.setValueFromCoords(5, row, this.certificateList.records[index].droneDate, true);
                                            this.mySpreadsheet.setValueFromCoords(6, row, this.certificateList.records[index].droneWeight, true);
                                            this.mySpreadsheet.setValueFromCoords(7, row, this.certificateList.records[index].droneMtow, true);

                                            this.mySpreadsheet.setValueFromCoords(8, row, this.certificateList.records[index].airField, true);

                                            this.mySpreadsheet.setValueFromCoords(14, row, this.certificateList.records[index].flightPurpose, true);

                                            this.mySpreadsheet.setValueFromCoords(15, row, this.certificateList.records[index].instructorName, true);
                                            this.mySpreadsheet.setValueFromCoords(16, row, this.certificateList.records[index].instructorNo, true);

                                            break;
                                        }
                                    }                                                     
                                    
                                }                       
                        
                            }                        
                        }  
                    }

                    this.disableButton1 = false;
                    this.isProgress = false;                    
                })
        },        
        async getOrganizationInfo() {
            const config = {
                method: 'get',
                url: '/certificate/organizationinfo',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            return axios(config)
                .then((res) => {
                    this.organizationInfo = res.data;
                });
        },
        async getDroneInfo() {
            const self = this;
            const config = {
                method: 'get',
                url: '/certificate/droneinfo',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            return axios(config)
                .then((res) => {
                    this.droneInfo = res.data;

                    this.droneInfo.forEach((data)=> {
                        self.dronInfoModalIndex++;
                        data['orderNo'] = self.dronInfoModalIndex;                        
                        data['action'] = '<button class="btn-detail" onclick="droneInfoSelect(\'' + data.uuid + '\')">선택</button>'

                        self.droneInfoList.rows.push(data)
                    })                    

                });
        },
        async getAirFieldInfo() {
            const self = this;
            const config = {
                method: 'get',
                url: '/certificate/airfield',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            return axios(config)
                .then((res) => {
                    this.airField = res.data;

                    this.airField.forEach((data)=> {
                        self.airFieldModalIndex++;
                        data['orderNo'] = self.airFieldModalIndex;                        
                        data['action'] = '<button class="btn-detail" onclick="airFieldSelect(\'' + data.uuid + '\')">선택</button>'

                        self.airFieldList.rows.push(data)
                    })   

                });
        },
        async getFlightPurpose() {
            const config = {
                method: 'get',
                url: '/certificate/purpose',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            return axios(config)
                .then((res) => {
                    this.flightPurpose = res.data;                   

                    this.flightPurpose.forEach(data => {
                        this.checkboxes.push({
                            label: data.purpose,
                            value: false,
                        })
                    })
                });
        },
        async getInstructorInfo() {
            const self = this;
            const config = {
                method: 'get',
                url: '/certificate/instructor',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            return axios(config)
                .then((res) => {
                    this.instructor = res.data;

                    this.instructor.forEach((data)=> {
                        self.instructorModalIndex++;
                        data['orderNo'] = self.instructorModalIndex;                        
                        data['action'] = '<button class="btn-detail" onclick="instructorSelect(\'' + data.uuid + '\')">선택</button>'

                        self.instructorList.rows.push(data)
                    });   
                });
        },
        async getIssuingNumber() {
            const config = {
                method: 'get',
                url: '/certificate/number',
                headers: {
                    'Content-Type': 'application/json'                
                }
            };

            await axios(config)
                .then((res) => {
                    this.issue_number = res.data;
                });
        },
        async updateIssuingNumber() {

            const data = JSON.stringify({
                "issuingNo": this.issue_number
            });

            const config = {
                method: 'put',
                url: '/certificate/number',
                headers: {
                    'Content-Type': 'application/json'                
                },
                data: data
            };

            await axios(config);                
        },        
        selectionActive(instance, x1, y1, x2, y2) {

            if(!instance) {
                return;
            }

            if (this.source === "issue") {                
                let selectionDataArea = 22;
                
                if (this.flightList.length > 12) {                    
                    selectionDataArea = this.flightList.length + 10;
                } 
                
                this.selectedRow = y1;
                this.selectedCol = x1;

                if(!this.isModal) {
                    if (x1 === x2 && y1 === y2) {

                        //초경량비행장치 선택팝업
                        if (x1 >= 2 && x1 <= 7 && y1 >= 11 && y1 <= selectionDataArea) {                            
                            this.dronInfoModal = true;
                            this.isModal = true;
                        }

                        //비행장소 선택팝업
                        if (x1 === 8 && y1 >= 11 && y1 <= selectionDataArea) {
                            this.airFieldModal = true;
                            this.isModal = true;
                        }

                        //비행목적 선택팝업
                        if (x1 === 14 && y1 >= 11 && y1 <= selectionDataArea) {
                            this.purposeModal = true;
                            this.isModal = true;
                        }

                        //지도조종자 선택팝업
                        if (x1 >= 15 && x1 <=16 && y1 >= 11 && y1 <= selectionDataArea) {
                            this.instructorModal = true;
                            this.isModal = true;
                        }                    
                    }
                }
            }
        },        
        setIssueJSpreadsheet(flightListLength) {

            var data3 = [
                ['발급번호', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                ['비행경력증명서(Certificate of Flight Experience)', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                ['','','','','','','','','','','','','','','','','',''],
                ['1. 성명(Name):','','','','2. 소속(Company):','','','','3. 생년월일(D.O.B)/여권번호(Passport No.):','','','','','','4. 연락처(Phone No.):','','',''],
                ['①\n\n일자\n(Date)', '②\n\n비행\n횟수\n(No. of\nFlight)', '③\n\n초경량비행장치(Ultra-light Vehicle)', '','','','','','④\n\n비행장소\n(An\nAirfield)', '⑤\n\n비행시간\n(hrs)\n\n(Flight\nTime)', '⑥\n\n임무별 비행시간\n(Flight Time of Duty)', '','','','⑦\n\n비행\n목적\n(Purpose of Flight)\n\n(훈련내용)\n(Contents of Training)','⑧\n\n지도조종자\n(Instructor)','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','종류\n\n(Category)','형식\n\n(Type)','신고\n번호\n(Report No.)','최종\n인증\n검사일','자체 중량\n(kg)\n(Empty Weight)','최대\n이륙중량\n(kg)\n(MTOW)','','','기장\n(Solo)','훈련\n(Training)','교관\n(Trainer)','소계\n(Total)','','성명\n(Name)','자격\n번호\n(No. of the License)','서명\n(Signature)'],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','','','','','','','','','']
            ]

            // 비행 기록 출력
            for(let index = 0; index < flightListLength; index++) {
                data3.push(['','','','','','','','','','=ROUND(SUM(K'+(index+12)+':M'+(index+12)+'),1)','','','','=ROUND(SUM(K'+(index+12)+':M'+(index+12)+'),1)','','','','']);
            }            

            data3.push(['계 (Total)','=ROUND(SUM(B12:B'+(flightListLength+11)+'),1)','','','','','','','','=ROUND(SUM(J12:J'+(flightListLength+11)+'),1)','=ROUND(SUM(K12:K'+(flightListLength+11)+'),1)','=ROUND(SUM(L12:L'+(flightListLength+11)+'),1)','=ROUND(SUM(M12:M'+(flightListLength+11)+'),1)','=ROUND(SUM(N12:N'+(flightListLength+11)+'),1)','','','','']);
            data3.push(['「무인비행장치 조종자 증명 운영세칙」 제9조에 따라 위와 같이 비행경력을 증명합니다.\nThis is to certify that above person has the flight experience in accordance with article 9 of the Operational Detailed Rules of Pilot of Unmanned Aerial Vehicle.','','','','','','','','','','','','','','','','','']);
            data3.push(['','','','','','','','','','','','','','','','','','']);
            data3.push(['','발급일 (Date of Issue) :','','','','','','발급기관명 (Issuing Organization) / 주소 (Address) :','','','','','','','','','','']);
            data3.push(['','발급책임자 :','','','(서명 또는 인)','','','전화번호 (Phone No.) :','','','','','','','','','','']);
            data3.push(['1-1','','','','','','','','','','','','','','','','','']);
            
            this.mySpreadsheet = jspreadsheet(document.getElementById('spreadsheet'), {
                data: data3,                
                columns: [
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:150 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 }                
                ],
                mergeCells: {
                    B1: [3,1],
                    A2: [18,1],
                    A4: [2,1],
                    C4: [2,1],
                    E4: [2,1],
                    G4: [2,1],
                    I4: [4,1],
                    M4: [2,1],
                    O4: [2,1],
                    Q4: [2,1],
                    A5: [1,7],
                    B5: [1,7],
                    C5: [6,4],
                    I5: [1,7],
                    J5: [1,7],
                    K5: [4,4],
                    O5: [1,7],
                    P5: [3,4],
                    C9: [1,3],
                    D9: [1,3],
                    E9: [1,3],
                    F9: [1,3],
                    G9: [1,3],
                    H9: [1,3],
                    K9: [1,3],
                    L9: [1,3],
                    M9: [1,3],
                    N9: [1,3],
                    P9: [1,3],
                    Q9: [1,3],
                    R9: [1,3]
                },
                minDimensions: [10,5],
                wordWrap: true,
                onselection: this.selectionActive,
                contextMenu: false,
            });    

            this.mySpreadsheet.setMerge('A' + (flightListLength+13).toString(),18,2);
            this.mySpreadsheet.setMerge('B' + (flightListLength+15).toString(),2,1);
            this.mySpreadsheet.setMerge('H' + (flightListLength+15).toString(),5,1);
            this.mySpreadsheet.setMerge('M' + (flightListLength+15).toString(),6,1);
            this.mySpreadsheet.setMerge('B' + (flightListLength+16).toString(),2,1);
            this.mySpreadsheet.setMerge('E' + (flightListLength+16).toString(),2,1);
            this.mySpreadsheet.setMerge('H' + (flightListLength+16).toString(),3,1);
            this.mySpreadsheet.setMerge('K' + (flightListLength+16).toString(),5,1);
            this.mySpreadsheet.setMerge('A' + (flightListLength+17).toString(),18,1);
            this.mySpreadsheet.setMerge('D' + (flightListLength+15).toString(),3,1);


            //cell type을 변경하여 자동계산이 가능하도록 함
            for(let row = 11; row < flightListLength+12; row++){
                var colB = this.mySpreadsheet.getCellFromCoords(1,row);
                var colJ = this.mySpreadsheet.getCellFromCoords(9,row);
                var colK = this.mySpreadsheet.getCellFromCoords(10,row);
                var colL = this.mySpreadsheet.getCellFromCoords(11,row);
                var colM = this.mySpreadsheet.getCellFromCoords(12,row);
                var colN = this.mySpreadsheet.getCellFromCoords(13,row);
                colB.type = "numeric";
                colJ.type = "numeric";
                colK.type = "numeric";
                colL.type = "numeric";
                colM.type = "numeric";
                colN.type = "numeric";

            }
            var CellB24 = this.mySpreadsheet.getCellFromCoords(1,   flightListLength+11);
            var CellJ24 = this.mySpreadsheet.getCellFromCoords(9,   flightListLength+11);
            var CellK24 = this.mySpreadsheet.getCellFromCoords(10,  flightListLength+11);
            var CellL24 = this.mySpreadsheet.getCellFromCoords(11,  flightListLength+11);
            var CellM24 = this.mySpreadsheet.getCellFromCoords(12,  flightListLength+11);
            var CellN24 = this.mySpreadsheet.getCellFromCoords(13,  flightListLength+11);
            CellB24.type = "numeric";
            CellJ24.type = "numeric";
            CellK24.type = "numeric";
            CellL24.type = "numeric";
            CellM24.type = "numeric";
            CellN24.type = "numeric";
            this.mySpreadsheet.setReadOnly([1,   flightListLength+11], true);
            this.mySpreadsheet.setReadOnly([9,   flightListLength+11], true);
            this.mySpreadsheet.setReadOnly([10,   flightListLength+11], true);
            this.mySpreadsheet.setReadOnly([11,   flightListLength+11], true);
            this.mySpreadsheet.setReadOnly([12,   flightListLength+11], true);
            this.mySpreadsheet.setReadOnly([13,   flightListLength+11], true);
        },        
        setJSpreadsheet() {
            var data3 = [
                ['발급번호', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                ['비행경력증명서(Certificate of Flight Experience)', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
                ['','','','','','','','','','','','','','','','','',''],
                ['1. 성명(Name):','','','','2. 소속(Company):','','','','3. 생년월일(D.O.B)/여권번호(Passport No.):','','','','','','4. 연락처(Phone No.):','','',''],
                ['①\n\n일자\n(Date)', '②\n\n비행\n횟수\n(No. of\nFlight)', '③\n\n초경량비행장치(Ultra-light Vehicle)', '','','','','','④\n\n비행장소\n(An\nAirfield)', '⑤\n\n비행시간\n(hrs)\n\n(Flight\nTime)', '⑥\n\n임무별 비행시간\n(Flight Time of Duty)', '','','','⑦\n\n비행\n목적\n(Purpose of Flight)\n\n(훈련내용)\n(Contents of Training)','⑧\n\n지도조종자\n(Instructor)','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','종류\n\n(Category)','형식\n\n(Type)','신고\n번호\n(Report No.)','최종\n인증\n검사일','자체 중량\n(kg)\n(Empty Weight)','최대\n이륙중량\n(kg)\n(MTOW)','','','기장\n(Solo)','훈련\n(Training)','교관\n(Trainer)','소계\n(Total)','','성명\n(Name)','자격\n번호\n(No. of the License)','서명\n(Signature)'],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K12:M12),1)','','','','=ROUND(SUM(K12:M12),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K13:M13),1)','','','','=ROUND(SUM(K13:M13),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K14:M14),1)','','','','=ROUND(SUM(K14:M14),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K15:M15),1)','','','','=ROUND(SUM(K15:M15),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K16:M16),1)','','','','=ROUND(SUM(K16:M16),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K17:M17),1)','','','','=ROUND(SUM(K17:M17),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K18:M18),1)','','','','=ROUND(SUM(K18:M18),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K19:M19),1)','','','','=ROUND(SUM(K19:M19),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K20:M20),1)','','','','=ROUND(SUM(K20:M20),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K21:M21),1)','','','','=ROUND(SUM(K21:M21),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K22:M22),1)','','','','=ROUND(SUM(K22:M22),1)','','','',''],
                ['','','','','','','','','','=ROUND(SUM(K23:M23),1)','','','','=ROUND(SUM(K23:M23),1)','','','',''],
                ['계 (Total)','=ROUND(SUM(B12:B23),1)','','','','','','','','=ROUND(SUM(J12:J23),1)','=ROUND(SUM(K12:K23),1)','=ROUND(SUM(L12:L23),1)','=ROUND(SUM(M12:M23),1)','=ROUND(SUM(N12:N23),1)','','','',''],
                ['「무인비행장치 조종자 증명 운영세칙」 제9조에 따라 위와 같이 비행경력을 증명합니다.\nThis is to certify that above person has the flight experience in accordance with article 9 of the Operational Detailed Rules of Pilot of Unmanned Aerial Vehicle.','','','','','','','','','','','','','','','','',''],
                ['','','','','','','','','','','','','','','','','',''],
                ['','발급일 (Date of Issue) :','','','','','','발급기관명 (Issuing Organization) / 주소 (Address) :','','','','','','','','','',''],
                ['','발급책임자 :','','','(서명 또는 인)','','','전화번호 (Phone No.) :','','','','','','','','','',''],
                ['1-1','','','','','','','','','','','','','','','','','']
            ]

            this.mySpreadsheet = jspreadsheet(document.getElementById('spreadsheet'), {
                data: data3,                
                columns: [
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:150 },
                    {type:'text', width:80 },
                    {type:'text', width:80 },
                    {type:'text', width:80 }                
                ],
                mergeCells: {
                    B1: [3,1],
                    A2: [18,1],
                    A4: [2,1],
                    C4: [2,1],
                    E4: [2,1],
                    G4: [2,1],
                    I4: [4,1],
                    M4: [2,1],
                    O4: [2,1],
                    Q4: [2,1],
                    A5: [1,7],
                    B5: [1,7],
                    C5: [6,4],
                    I5: [1,7],
                    J5: [1,7],
                    K5: [4,4],
                    O5: [1,7],
                    P5: [3,4],
                    C9: [1,3],
                    D9: [1,3],
                    E9: [1,3],
                    F9: [1,3],
                    G9: [1,3],
                    H9: [1,3],
                    K9: [1,3],
                    L9: [1,3],
                    M9: [1,3],
                    N9: [1,3],
                    P9: [1,3],
                    Q9: [1,3],
                    R9: [1,3],
                    A25: [18,2],
                    B27: [2,1],
                    H27: [5,1],
                    M27: [6,1],
                    B28: [2,1],
                    E28: [2,1],
                    H28: [3,1],
                    K28: [5,1],
                    A29: [18,1],
                    D27: [3,1]
                },
                minDimensions: [10,5],
                wordWrap: true,
                onselection: this.selectionActive,
                contextMenu: false,
            });    

            //cell type을 변경하여 자동계산이 가능하도록 함
            for(let row = 11; row < 24; row++){
                var colB = this.mySpreadsheet.getCellFromCoords(1,row);
                var colJ = this.mySpreadsheet.getCellFromCoords(9,row);
                var colK = this.mySpreadsheet.getCellFromCoords(10,row);
                var colL = this.mySpreadsheet.getCellFromCoords(11,row);
                var colM = this.mySpreadsheet.getCellFromCoords(12,row);
                var colN = this.mySpreadsheet.getCellFromCoords(13,row);
                colB.type = "numeric";
                colJ.type = "numeric";
                colK.type = "numeric";
                colL.type = "numeric";
                colM.type = "numeric";
                colN.type = "numeric";

            }
            var CellB24 = this.mySpreadsheet.getCellFromCoords(1,23);
            var CellJ24 = this.mySpreadsheet.getCellFromCoords(9,23);
            var CellK24 = this.mySpreadsheet.getCellFromCoords(10,23);
            var CellL24 = this.mySpreadsheet.getCellFromCoords(11,23);
            var CellM24 = this.mySpreadsheet.getCellFromCoords(12,23);
            var CellN24 = this.mySpreadsheet.getCellFromCoords(13,23);
            CellB24.type = "numeric";
            CellJ24.type = "numeric";
            CellK24.type = "numeric";
            CellL24.type = "numeric";
            CellM24.type = "numeric";
            CellN24.type = "numeric";
            this.mySpreadsheet.setReadOnly([1,23], true);
            this.mySpreadsheet.setReadOnly([9,23], true);
            this.mySpreadsheet.setReadOnly([10,23], true);
            this.mySpreadsheet.setReadOnly([11,23], true);
            this.mySpreadsheet.setReadOnly([12,23], true);
            this.mySpreadsheet.setReadOnly([13,23], true);
        },
        droneInfoSelect(value) {
            const self = this;
            const selData = self.droneInfoList.rows.find((element) => element.uuid === value)
            
            this.dronInfoModal = false;
            this.isModal = false;

            this.mySpreadsheet.setValueFromCoords(2, this.selectedRow, selData.category, true);
            this.mySpreadsheet.setValueFromCoords(3, this.selectedRow, selData.type, true);
            this.mySpreadsheet.setValueFromCoords(4, this.selectedRow, selData.no, true);
            this.mySpreadsheet.setValueFromCoords(5, this.selectedRow, selData.date, true);
            this.mySpreadsheet.setValueFromCoords(6, this.selectedRow, selData.weight, true);
            this.mySpreadsheet.setValueFromCoords(7, this.selectedRow, selData.mtow, true);
        },
        airFieldSelect(value) {
            const self = this;
            const selData = self.airFieldList.rows.find((element) => element.uuid === value)
            
            this.airFieldModal = false;
            this.isModal = false;

            this.mySpreadsheet.setValueFromCoords(8, this.selectedRow, selData.airfield, true);            
        },
        instructorSelect(value) {
            const self = this;
            const selData = self.instructorList.rows.find((element) => element.uuid === value)
            
            this.instructorModal = false;
            this.isModal = false;

            this.mySpreadsheet.setValueFromCoords(15, this.selectedRow, selData.name, true);
            this.mySpreadsheet.setValueFromCoords(16, this.selectedRow, selData.no, true);
            this.mySpreadsheet.setValueFromCoords(17, this.selectedRow, selData.signature, true);           
        },
        getCheckedValues() {
            const checkedItems = this.checkboxes.filter(checkbox => checkbox.value).map(checkbox => checkbox.label);
            this.checkedValues = checkedItems.join(', ');

            this.purposeModal = false;
            this.isModal = false;

            this.mySpreadsheet.setValueFromCoords(14, this.selectedRow, this.checkedValues, true);
        },
        async saveCertificate() {
            const self = this;
            const jsonData = {
                issuingNo: '',
                issuingDate: null,
                issuingOrganization: '',
                issuingAddress: '',
                issuingOfficer: '',
                issuingPhone: '',
                userName: '',
                userCompany: '',
                identifyNo: '',
                userPhone: '',
                userId: '',
                records: []
            };

            await this.getIssuingNumber();
            jsonData.issuingNo = this.issue_number;
            
            let dateStr = this.mySpreadsheet.getValueFromCoords(3, 26);
            let organizationStr = this.mySpreadsheet.getValueFromCoords(12, 26);
            jsonData.issuingOfficer = this.mySpreadsheet.getValueFromCoords(3, 27);
            jsonData.issuingPhone = this.mySpreadsheet.getValueFromCoords(10, 27);

            let dataSize = this.pageMaxRow;

            if (this.flightList.length > 12) {
                dateStr = this.mySpreadsheet.getValueFromCoords(3, this.flightList.length+14);
                organizationStr = this.mySpreadsheet.getValueFromCoords(12, this.flightList.length+14);

                jsonData.issuingOfficer = this.mySpreadsheet.getValueFromCoords(3, this.flightList.length+15);
                jsonData.issuingPhone = this.mySpreadsheet.getValueFromCoords(10, this.flightList.length+15);
                dataSize = this.flightList.length;
            }
            
            jsonData.issuingDate = new Date(`20${dateStr.replace(/\./g, '-')}`);
            
            let organizationSplit = organizationStr.split("/").map(part => part.trim());
            jsonData.issuingOrganization = organizationSplit[0];
            jsonData.issuingAddress = organizationSplit[1];

            jsonData.userName = this.mySpreadsheet.getValueFromCoords(2, 3);
            jsonData.userCompany = this.mySpreadsheet.getValueFromCoords(6, 3);
            jsonData.identifyNo = this.mySpreadsheet.getValueFromCoords(12, 3)
            jsonData.userPhone = this.mySpreadsheet.getValueFromCoords(16, 3);
            jsonData.userId = this.userInfo.uuid;

            for (let row = 11; row < dataSize+ 11; row++)
            {
                if (this.mySpreadsheet.getValueFromCoords(0, row) && this.mySpreadsheet.getValueFromCoords(1, row)) {

                    const record = {
                        issuingNo: this.issue_number,   
                        flightDate: null,
                        flightCount: 0,
                        droneCategory: '',
                        droneType: '',
                        droneNo: '',
                        droneDate: '',
                        droneWeight: 0,
                        droneMtow: 0,
                        airField: '',
                        soloTime: 0,
                        trainingTime: 0,
                        trainerTime: 0,
                        flightPurpose: '',
                        instructorName: '',
                        instructorNo: '',
                        certificateId: '',  //저장시에는 비어있고, 서버에서 db create 할 때 생성됨
                    }

                    let recordDateStr = this.mySpreadsheet.getValueFromCoords(0, row);
                    record.flightDate = new Date(`20${recordDateStr.replace(/\./g, '-')}`);
                    record.flightCount = Number(this.mySpreadsheet.getValueFromCoords(1, row));
                    record.droneCategory = this.mySpreadsheet.getValueFromCoords(2, row);
                    record.droneType = this.mySpreadsheet.getValueFromCoords(3, row);
                    record.droneNo = this.mySpreadsheet.getValueFromCoords(4, row);
                    record.droneDate = this.mySpreadsheet.getValueFromCoords(5, row);
                    record.droneWeight = Number(this.mySpreadsheet.getValueFromCoords(6, row));
                    record.droneMtow = Number(this.mySpreadsheet.getValueFromCoords(7, row));
                    record.airField = this.mySpreadsheet.getValueFromCoords(8, row);
                    record.soloTime = Number(this.mySpreadsheet.getValueFromCoords(10, row));
                    record.trainingTime = Number(this.mySpreadsheet.getValueFromCoords(11, row));
                    record.trainerTime = Number(this.mySpreadsheet.getValueFromCoords(12, row));
                    record.flightPurpose = this.mySpreadsheet.getValueFromCoords(14, row);
                    record.instructorName = this.mySpreadsheet.getValueFromCoords(15, row);
                    record.instructorNo = this.mySpreadsheet.getValueFromCoords(16, row);

                    jsonData.records.push(record);                                                
                }
                else
                {
                    console.log('this.mySpreadsheet.getValueFromCoords(0, row) or this.mySpreadsheet.getValueFromCoords(1, row) is null');
                }               

            }

            // 비행기록 데이터가 없을 경우
            if (jsonData.records.length === 0) {
                self.$swal.fire({title: '발급 실패', text: '비행날짜, 비행횟수 형식을 확인하세요.', icon: 'error'});
                return Promise.reject();                
            }
            
            const config = {
                method: 'post',                
                url: '/certificate',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: jsonData
            }

            try {
                await axios(config)
                    .then((res) => {                    
                    console.log('res.data: ' + res.data);
                    console.log('res.data stringify' + JSON.stringify(res.data));                  
                });
                
                await this.updateIssuingNumber();

                //증명서 발급 alert
                self.$swal
                    .fire({title: '발급 완료', text: jsonData.userName + '님 증명서가 발급 되었습니다.', icon: 'success'})
                    .then(() => {
                        self.$router.replace({name: 'certificatelist'})
                    })
                
            } catch(error) {
                console.error('Error certificate post', error);

                //증명서 발급 실패 alert
                self.$swal.fire({title: '발급 실패', text: '비행날짜, 비행횟수 형식을 확인하세요.', icon: 'error'})

            }
        },
        closeDronInfoModal() {
            this.dronInfoModal = false;
            this.isModal = false;
        },
        closeAirFieldModal() {
            this.airFieldModal = false;
            this.isModal = false;
        },
        closeInstructorModal() {
            this.instructorModal = false;
            this.isModal = false;
        },
        closePurposeModal() {
            this.purposeModal = false;
            this.isModal = false;
        },
        selectAllCheckboxes() {
            const newValue = this.checkSelectAll;
            this.checkboxes.forEach((checkbox) => {
                checkbox.value = newValue;
            });
        },
        moveBack() {
            if (this.source == "issue") {
                this.$router.replace({name: 'certificateissue'})
            } else if (this.source ==="reissue") {
                this.$router.replace({name: 'certificatelist'})
            }
        },
        padZero(num) {
            return num.toString().padStart(2,'0');
        },
        makeExcel() {
            this.workbook = new exceljs.Workbook();
            var userName = this.certificateList.userName.replace(/[*?:/[\]]/g, '');            
            var sheetName = '비행경력증명서(' + userName + ')';
            this.sheet = this.workbook.addWorksheet(sheetName, {
                pageSetup: {
                    paperSize: 9,
                    orientation: 'landscape',
                    fitToPage: true,
                    fitToWidth: 1,
                    fitToHeight: 0,
                    margins: {                        
                        top: 1.3/2.53, //3.8
                        left: 1.8/2.53, //4.6                        
                        bottom: 1.3/2.53,
                        right: 1.8/2.53,
                        header: 0.8/2.53, //2
                        footer: 0.8/2.53
                    }
                },
                views: [{}]
            });

            let totalPageCount = this.certificateList.records.length == 0 ? 1 : Math.ceil(this.certificateList.records.length/12);

            this.setPageMergeCell(totalPageCount);
            this.setPageSheetSize(totalPageCount);
            this.setPageSheetData(totalPageCount);
            this.setPageDrawBorder(totalPageCount);

            for(let i = 0; i < totalPageCount; i++) {
                this.sheet.getRow((i+1)*29).addPageBreak();
            }

            var issueDate = new Date(this.issue_date);
            var formattedDate = `${issueDate.getFullYear()}${this.padZero(issueDate.getMonth() + 1)}${this.padZero(issueDate.getDate())}`;
            
            var fileName = formattedDate + '_비행경력증명서_' + userName;

            this.download(this.workbook, fileName);
        },
        setPageMergeCell(totalPageCount) {

            for (let row = 0; row < totalPageCount; row++)
            {
                this.sheet.mergeCells('B' + (1  + 29 * row).toString() + ': D' + (1  + 29 * row).toString());
                this.sheet.mergeCells('A' + (2  + 29 * row).toString() + ': R' + (2  + 29 * row).toString());
                this.sheet.mergeCells('A' + (4  + 29 * row).toString() + ': B' + (4  + 29 * row).toString());
                this.sheet.mergeCells('C' + (4  + 29 * row).toString() + ': D' + (4  + 29 * row).toString());
                this.sheet.mergeCells('E' + (4  + 29 * row).toString() + ': F' + (4  + 29 * row).toString());
                this.sheet.mergeCells('G' + (4  + 29 * row).toString() + ': H' + (4  + 29 * row).toString());
                this.sheet.mergeCells('I' + (4  + 29 * row).toString() + ': L' + (4  + 29 * row).toString());
                this.sheet.mergeCells('M' + (4  + 29 * row).toString() + ': N' + (4  + 29 * row).toString());
                this.sheet.mergeCells('O' + (4  + 29 * row).toString() + ': P' + (4  + 29 * row).toString());
                this.sheet.mergeCells('Q' + (4  + 29 * row).toString() + ': R' + (4  + 29 * row).toString());
                this.sheet.mergeCells('A' + (5  + 29 * row).toString() + ': A' + (11 + 29 * row).toString());
                this.sheet.mergeCells('B' + (5  + 29 * row).toString() + ': B' + (11 + 29 * row).toString());
                this.sheet.mergeCells('C' + (5  + 29 * row).toString() + ': H' + (8  + 29 * row).toString());
                this.sheet.mergeCells('I' + (5  + 29 * row).toString() + ': I' + (11 + 29 * row).toString());
                this.sheet.mergeCells('J' + (5  + 29 * row).toString() + ': J' + (11 + 29 * row).toString());
                this.sheet.mergeCells('K' + (5  + 29 * row).toString() + ': N' + (8  + 29 * row).toString());
                this.sheet.mergeCells('O' + (5  + 29 * row).toString() + ': O' + (11 + 29 * row).toString());
                this.sheet.mergeCells('P' + (5  + 29 * row).toString() + ': R' + (8  + 29 * row).toString());
                this.sheet.mergeCells('K' + (9  + 29 * row).toString() + ': K' + (11 + 29 * row).toString());
                this.sheet.mergeCells('L' + (9  + 29 * row).toString() + ': L' + (11 + 29 * row).toString());
                this.sheet.mergeCells('M' + (9  + 29 * row).toString() + ': M' + (11 + 29 * row).toString());
                this.sheet.mergeCells('N' + (9  + 29 * row).toString() + ': N' + (11 + 29 * row).toString());
                this.sheet.mergeCells('P' + (9  + 29 * row).toString() + ': P' + (11 + 29 * row).toString());
                this.sheet.mergeCells('Q' + (9  + 29 * row).toString() + ': Q' + (11 + 29 * row).toString());
                this.sheet.mergeCells('R' + (9  + 29 * row).toString() + ': R' + (11 + 29 * row).toString());
                this.sheet.mergeCells('A' + (25 + 29 * row).toString() + ': R' + (26 + 29 * row).toString());
                this.sheet.mergeCells('B' + (27 + 29 * row).toString() + ': C' + (27 + 29 * row).toString());
                this.sheet.mergeCells('D' + (27 + 29 * row).toString() + ': F' + (27 + 29 * row).toString());
                this.sheet.mergeCells('H' + (27 + 29 * row).toString() + ': L' + (27 + 29 * row).toString());
                this.sheet.mergeCells('M' + (27 + 29 * row).toString() + ': R' + (27 + 29 * row).toString());
                this.sheet.mergeCells('B' + (28 + 29 * row).toString() + ': C' + (28 + 29 * row).toString());
                this.sheet.mergeCells('E' + (28 + 29 * row).toString() + ': F' + (28 + 29 * row).toString());
                this.sheet.mergeCells('H' + (28 + 29 * row).toString() + ': J' + (28 + 29 * row).toString());
                this.sheet.mergeCells('K' + (28 + 29 * row).toString() + ': O' + (28 + 29 * row).toString());
            }

        },        
        setPageSheetSize(totalPageCount) {
            this.sheet.getColumn(1).width = 8.38 + 0.63;
            this.sheet.getColumn(2).width = 8.63 + 0.63;
            this.sheet.getColumn(3).width = 9.13 + 0.63;
            this.sheet.getColumn(4).width = 8.38 + 0.63;
            this.sheet.getColumn(5).width = 9.5 + 0.63;

            this.sheet.getColumn(6).width = 8.38 + 0.63;
            this.sheet.getColumn(7).width = 8.38 + 0.63;
            this.sheet.getColumn(8).width = 8.38 + 0.63;
            this.sheet.getColumn(9).width = 9.25 + 0.63;

            this.sheet.getColumn(10).width = 8.38 + 0.63;
            this.sheet.getColumn(11).width = 8.38 + 0.63;
            this.sheet.getColumn(12).width = 8.38 + 0.63;
            this.sheet.getColumn(13).width = 8.38 + 0.63;
            this.sheet.getColumn(14).width = 8.38 + 0.63;
            this.sheet.getColumn(15).width = 17.13 + 0.63;

            this.sheet.getColumn(16).width = 8.38 + 0.63;
            this.sheet.getColumn(17).width = 8.38 + 0.63;
            this.sheet.getColumn(18).width = 9.13 + 0.63;

            for (let row = 0; row < totalPageCount; row++) {

                this.sheet.getRow(1  + 29 * row).height = 30;
                this.sheet.getRow(2  + 29 * row).height = 25;
                this.sheet.getRow(3  + 29 * row).height = 7.5;
                this.sheet.getRow(4  + 29 * row).height = 25;
                this.sheet.getRow(5  + 29 * row).height = 15.75;
                this.sheet.getRow(6  + 29 * row).height = 15.75;
                this.sheet.getRow(7  + 29 * row).height = 15.75;
                this.sheet.getRow(8  + 29 * row).height = 15.75;
                this.sheet.getRow(9  + 29 * row).height = 30;
                this.sheet.getRow(10 + 29 * row).height = 25;
                this.sheet.getRow(11 + 29 * row).height = 25;
                this.sheet.getRow(12 + 29 * row).height = 30;
                this.sheet.getRow(13 + 29 * row).height = 30;
                this.sheet.getRow(14 + 29 * row).height = 30;
                this.sheet.getRow(15 + 29 * row).height = 30;
                this.sheet.getRow(16 + 29 * row).height = 30;
                this.sheet.getRow(17 + 29 * row).height = 30;
                this.sheet.getRow(18 + 29 * row).height = 30;
                this.sheet.getRow(19 + 29 * row).height = 30;
                this.sheet.getRow(20 + 29 * row).height = 30;
                this.sheet.getRow(21 + 29 * row).height = 30;
                this.sheet.getRow(22 + 29 * row).height = 30;
                this.sheet.getRow(23 + 29 * row).height = 30;
                this.sheet.getRow(24 + 29 * row).height = 30;
                this.sheet.getRow(25 + 29 * row).height = 35;
                this.sheet.getRow(26 + 29 * row).height = 7;
                this.sheet.getRow(27 + 29 * row).height = 20;
                this.sheet.getRow(28 + 29 * row).height = 20;
                this.sheet.getRow(29 + 29 * row).height = 15;
            }
        },        
        setPageSheetData(totalPageCount) {

            let totalFight = 0; // 총 비행 횟수
            let totalHours = 0.0; // 총 비행 시간
            let totalSolo = 0.0;  // 총 기장 시간
            let totalTraining = 0.0; //총 훈련시간
            let totalTrainer = 0.0;  // 총 교관
            let subTotalHours = 0.0; // 임무별 비행시간 총합
            
            let pageTotalFight = 0;       // 페이지별 총 비행 횟수
            let pageTotalHours = 0.0;    // 페이지별 총 비행 시간
            let pageTotalSolo = 0.0;      // 페이지별 총 기장 시간
            let pageTotalTraining = 0.0; // 페이지별  총 훈련시간
            let pageTotalTrainer = 0.0;   // 페이지별  총 교관
            let preRowNum = 11;         // 이전 열 개수            

            let Gulim = "Gulim";
            let MalgunGothic = "Malgun Gothic";
            let Dotum = "돋움";
            let Center = "center";
            let Left = "left";

            // 비행기록
            for (let row = 12; row < this.certificateList.records.length + 13; row++) {
                
                let rowNum = row + ( Math.floor( row / 12 ) - 1 ) * 17;

                preRowNum++;

                if (preRowNum != rowNum) {
                    
                    console.log('== preRowNum == ' + preRowNum);

                    this.setCell('B' + (preRowNum).toString(), pageTotalFight, MalgunGothic, 8);

                    //비행시간 소계
                    this.setCell('J' + (preRowNum).toString(), pageTotalHours, MalgunGothic, 8);

                    //기장 소계
                    this.setCell('K' + (preRowNum).toString(), pageTotalSolo, MalgunGothic, 8);

                    //훈련 소계
                    this.setCell('L' + (preRowNum).toString(), pageTotalTraining, MalgunGothic, 8);

                    //교관 소계
                    this.setCell('M' + (preRowNum).toString(), pageTotalTrainer, MalgunGothic, 8);

                    //비행시간 소계2
                    this.setCell('N' + (preRowNum).toString(), pageTotalHours, MalgunGothic, 8);

                    preRowNum = rowNum;

                    pageTotalFight    = 0;      // 페이지별 총 비행 횟수
                    pageTotalHours    = 0.0;    // 페이지별 총 비행 시간
                    pageTotalSolo     = 0.0;    // 페이지별 총 기장 시간
                    pageTotalTraining = 0.0;    // 페이지별  총 훈련시간
                    pageTotalTrainer  = 0.0;    // 페이지별  총 교관
                }                
                
                if (row != this.certificateList.records.length + 12) {
                    for (let i =0; i < 18; i++) {
                        if ( i ===9 || i ===13 ) continue;
                        this.setCell(String.fromCharCode( i + 65 ) + rowNum, this.mySpreadsheet.getValueFromCoords(i, row - 1), MalgunGothic, 8);
                    }
                    
                    //비행목적 텍스트 길이에 따른 폰트 사이즈 수정
                    const purposeText = this.mySpreadsheet.getValueFromCoords(14, row - 1);
                    if(purposeText.length>65) {
                        this.sheet.getCell(String.fromCharCode(79) + rowNum).font = {
                            name: MalgunGothic,
                            size: 5,
                            bold: false,
                        };
                    } else if(purposeText.length>30) {
                        this.sheet.getCell(String.fromCharCode(79) + rowNum).font = {
                            name: MalgunGothic,
                            size: 6,
                            bold: false,
                        };
                    } else if(purposeText.length>15) {
                        this.sheet.getCell(String.fromCharCode(79) + rowNum).font = {
                            name: MalgunGothic,
                            size: 7,
                            bold: false,
                        };
                    } 

                    //지도조종자 이름 텍스트 길이에 따른 폰트 사이즈 수정                    
                    const nameText = this.mySpreadsheet.getValueFromCoords(15, row - 1)
                    if(nameText) {
                        if(nameText.length>5) {
                            this.sheet.getCell(String.fromCharCode(80) + rowNum).font = {
                                name: MalgunGothic,
                                size: 6,
                                bold: false,
                            };                            
                        } else if(nameText.length>4) {
                            this.sheet.getCell(String.fromCharCode(80) + rowNum).font = {
                                name: MalgunGothic,
                                size: 7,
                                bold: false,
                            };
                        } 
                    }
                    
                    subTotalHours =  Math.round(Number(this.mySpreadsheet.getValueFromCoords(10, row - 1)) * 10 ) / 10;
                    subTotalHours += Math.round(Number(this.mySpreadsheet.getValueFromCoords(11, row - 1)) * 10 ) / 10;
                    subTotalHours += Math.round(Number(this.mySpreadsheet.getValueFromCoords(12, row - 1)) * 10 ) / 10;
                    subTotalHours = Math.round(subTotalHours * 10) / 10;

                    if (this.mySpreadsheet.getValueFromCoords(0, row - 1) != '') {
                        
                        this.setCell(String.fromCharCode(74) + rowNum, subTotalHours, MalgunGothic, 8);
                        this.setCell(String.fromCharCode(78) + rowNum, subTotalHours, MalgunGothic, 8);   
                    }

                    totalFight    += Number(this.mySpreadsheet.getValueFromCoords(1, row - 1)); // 총 비행 횟수
                    totalHours    += subTotalHours; // 총 비행 시간
                    totalSolo     += Math.round(Number(this.mySpreadsheet.getValueFromCoords(10, row - 1)) * 10 ) / 10;  // 총 기장 시간
                    totalTraining += Math.round(Number(this.mySpreadsheet.getValueFromCoords(11, row - 1)) * 10 ) / 10; //총 훈련시간
                    totalTrainer  += Math.round(Number(this.mySpreadsheet.getValueFromCoords(12, row - 1)) * 10 ) / 10;  // 총 교관
                    
                    pageTotalFight    += Number(this.mySpreadsheet.getValueFromCoords(1, row - 1)); // 페이지별 총 비행 횟수
                    pageTotalHours    += subTotalHours; // 총 비행 시간
                    pageTotalSolo     += Math.round(Number(this.mySpreadsheet.getValueFromCoords(10, row - 1)) * 10 ) / 10;  // 페이지별  총 기장 시간
                    pageTotalTraining += Math.round(Number(this.mySpreadsheet.getValueFromCoords(11, row - 1)) * 10 ) / 10; // 페이지별  총 훈련시간
                    pageTotalTrainer  += Math.round(Number(this.mySpreadsheet.getValueFromCoords(12, row - 1)) * 10 ) / 10;  // 페이지별  총 교관
                                        
                } else {                    
                    if (rowNum  != (29 * totalPageCount + 12 )) {
                        this.setCell('I' + rowNum,  "-", MalgunGothic, 8, Center);
                        this.setCell('J' + rowNum, "이", MalgunGothic, 8, Center);
                        this.setCell('K' + rowNum, "하", MalgunGothic, 8, Center);
                        this.setCell('L' + rowNum, "여", MalgunGothic, 8, Center);
                        this.setCell('M' + rowNum, "백", MalgunGothic, 8, Center);
                        this.setCell('N' + rowNum,  "-", MalgunGothic, 8, Center);                        
                    } 
                } 
            }            

            for (let row = 0; row < totalPageCount; row++) {
                this.setCell('A' + (1  + 29 * row).toString(), "발급번호", Gulim, 11, Center, true);
                this.setCell(
                    'A' + (2  + 29 * row).toString(),
                    "비행경력증명서 (Certificate of Flight Experience)",
                    Gulim,
                    16,
                    Center,
                    true
                );

                this.setCell('A' + (4  + 29 * row).toString(), "1.성명(Name):", MalgunGothic, 11);            
                this.setCell('E' + (4  + 29 * row).toString(), "2. 소속(Company):", MalgunGothic, 11);            
                this.setCell(
                    'I' + (4  + 29 * row).toString(),
                    "3. 생년월일(D.O.B)/여권번호(Passport No.):",
                    MalgunGothic,
                    10
                );            
                this.setCell('O' + (4  + 29 * row).toString(), "4.연락처(Phone No.):", MalgunGothic, 11);
                
                this.setCell('A' + (5  + 29 * row).toString(), "① 일자 (Date)", Gulim, 11);
                this.setCell('B' + (5  + 29 * row).toString(), "② 비행 횟수 (No. of Flight)", Gulim, 11);
                this.setCell('C' + (5  + 29 * row).toString(), "③ 초경량비행장치 (Ultra-light Vehicle)", Gulim, 11);
                this.setCell('I' + (5  + 29 * row).toString(), "④ 비행장소 (An Airfield)", Gulim, 11);
                this.setCell('J' + (5  + 29 * row).toString(), "⑤ 비행시간 (hrs) (Flight Time)", Gulim, 11);
                this.setCell('K' + (5  + 29 * row).toString(), "⑥ 임무별 비행시간 (Flight Time of Duty)", Gulim, 11);
                this.setCell(
                    'O' + (5  + 29 * row).toString(),
                    "⑦ 비행 목적 (Purpose of Flight) (훈련내용) (Contents of Training)",
                    Gulim,
                    11
                );
                this.setCell('P' + (5  + 29 * row).toString(), "⑧ 지도조종자 (Instructor)", Gulim, 11);

                this.setCell('C' + (9  + 29 * row).toString(), "종류", Dotum, 10);
                this.setCell('D' + (9  + 29 * row).toString(), "형식", Dotum, 10);
                this.setCell('E' + (9  + 29 * row).toString(), "신고", Dotum, 10);
                this.setCell('F' + (9  + 29 * row).toString(), "최종", Dotum, 10);
                this.setCell('G' + (9  + 29 * row).toString(), "자체중량", Dotum, 10);
                this.setCell('H' + (9  + 29 * row).toString(), "최대 이륙중량", Dotum, 10);

                this.setCell('C' + (10  + 29 * row).toString(), "(Category)", Dotum, 7);
                this.setCell('D' + (10  + 29 * row).toString(), "(Type)", Dotum, 7);
                this.setCell('E' + (10  + 29 * row).toString(), "번호", Dotum, 10);
                this.setCell('F' + (10  + 29 * row).toString(), "인증", Dotum, 10);
                this.setCell('G' + (10  + 29 * row).toString(), "(kg)", Dotum, 10);
                this.setCell('H' + (10  + 29 * row).toString(), "(kg)", Dotum, 10);

                this.setCell('E' + (11  + 29 * row).toString(), "(Report No.)", Dotum, 7);
                this.setCell('F' + (11  + 29 * row).toString(), "검사일", Dotum, 10);
                this.setCell('G' + (11  + 29 * row).toString(), "(Empty Weight)", Dotum, 6);
                this.setCell('H' + (11  + 29 * row).toString(), "(MTOW)", Dotum, 7);

                this.setCell('K' + (9  + 29 * row).toString(), "기장 (Solo)", Gulim, 11);
                this.setCell('L' + (9  + 29 * row).toString(), "훈련 (Training)", Gulim, 11);
                this.setCell('M' + (9  + 29 * row).toString(), "교관 (Trainer)", Gulim, 11);
                this.setCell('N' + (9  + 29 * row).toString(), "소계 (Total)", Gulim, 11);

                this.setCell('P' + (9  + 29 * row).toString(), "성명 (Name)", Dotum, 11);
                this.setCell('Q' + (9  + 29 * row).toString(), "자격 번호 (No. of the License)", Dotum, 11);
                this.setCell('R' + (9  + 29 * row).toString(), "서명 (Signature)", Dotum, 11);      

                this.setCell('A' + (24  + 29 * row).toString(), "계\n(Total)", Gulim, 8);            

                //footter
                this.setCell(
                    'A' + (25  + 29 * row).toString(),
                    "「무인비행장치 조종자 증명 운영세칙」 제9조에 따라 위와 같이 비행경력을 증명합니다.\r This is to certify that above person has the flight experience in accordance with article 9 of the Operational Detailed Rules of Pilot of Unmanned Aerial Vehicle.",
                    Gulim,
                    12                
                );

                this.setCell('B' + (27  + 29 * row).toString(), "발급일(Date of Issue):", MalgunGothic, 11, Left);            
                this.setCell(
                    'H' + (27  + 29 * row).toString(),
                    "발급기관명(Issuing Organization)/주소(Address) :",
                    MalgunGothic,
                    11,
                    Left
                );
                this.setCell(
                    'M' + (27  + 29 * row).toString(),
                    "아쎄따 / 경기도 연천군 전곡읍 양원로46번길 20",
                    MalgunGothic,
                    11,
                    Left
                );
                this.setCell('B' + (28  + 29 * row).toString(), "발급책임자: ", MalgunGothic, 11, Left);            
                this.setCell('E' + (28  + 29 * row).toString(), "(서명 또는 인)", MalgunGothic, 11);
                this.setCell('H' + (28  + 29 * row).toString(), "전화번호(Phone No.):", MalgunGothic, 11, Left);            
                
                //페이지번호
                this.setCell('I' + (29  + 29 * row).toString(), totalPageCount.toString() + '-' + (1 + row).toString(), MalgunGothic, 11);

                //발급번호
                this.setCell('B' + (1  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(1, 0), Gulim, 11, Center, true);
                
                //성명
                this.setCell('C' + (4  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(2, 3), MalgunGothic, 11);

                //소속
                this.setCell('G' + (4  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(6, 3), MalgunGothic, 11);

                //생년월일
                this.setCell('M' + (4  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(12, 3), MalgunGothic, 11);

                //연락처
                this.setCell('Q' + (4  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(16, 3), MalgunGothic, 11);
                
                if (row == totalPageCount - 1) {
                    //비행횟수 소계
                    this.setFormula('B' + (24  + 29 * row).toString(), totalFight, MalgunGothic, 8);

                    //비행시간 소계
                    if (totalHours === 0) {
                        this.setCell('J' + (24  + 29 * row).toString(), totalHours, MalgunGothic, 8);
                    } else {
                        this.setFormula('J' + (24  + 29 * row).toString(), totalHours, MalgunGothic, 8);
                    }                

                    //기장 소계
                    if (totalSolo === 0) {
                        this.setCell('K' + (24  + 29 * row).toString(), totalSolo, MalgunGothic, 8);
                    } else {
                        this.setFormula('K' + (24  + 29 * row).toString(), totalSolo, MalgunGothic, 8);
                    }

                    //훈련 소계
                    if (totalTraining === 0) {
                        this.setCell('L' + (24  + 29 * row).toString(), totalTraining, MalgunGothic, 8);
                    } else {
                        this.setFormula('L' + (24  + 29 * row).toString(), totalTraining, MalgunGothic, 8);
                    }                

                    //교관 소계
                    if (totalTrainer === 0) {
                        this.setCell('M' + (24  + 29 * row).toString(), totalTrainer, MalgunGothic, 8);
                    } else {
                        this.setFormula('M' + (24  + 29 * row).toString(), totalTrainer, MalgunGothic, 8);
                    }                

                    //비행시간 소계2
                    if (totalHours === 0) {
                        this.setCell('N' + (24  + 29 * row).toString(), totalHours, MalgunGothic, 8);
                    } else {
                        this.setFormula('N' + (24  + 29 * row).toString(), totalHours, MalgunGothic, 8);
                    }                    
                }                

                //발급일
                this.setCell('D' + (27  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(3, this.certificateList.records.length > 12 ? (this.certificateList.records.length + 14) : 26), MalgunGothic, 11);

                //발급기관정보
                this.setCell(
                    'M' + (27  + 29 * row).toString(),
                    this.mySpreadsheet.getValueFromCoords(12, this.certificateList.records.length > 12 ? (this.certificateList.records.length + 14) : 26),
                    MalgunGothic,
                    11
                );

                //발급책임자
                this.setCell('D' + (28  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(3, this.certificateList.records.length > 12 ? (this.certificateList.records.length + 15) : 27), MalgunGothic, 11);

                //발급연락처
                this.setCell('K' + (28  + 29 * row).toString(), this.mySpreadsheet.getValueFromCoords(10, this.certificateList.records.length > 12 ? (this.certificateList.records.length + 15) : 27), MalgunGothic, 11);
            }

        },        
        async download(workbook, fileName){
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = fileName + ".xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
        },
        setCell(
            cellName,
            msg,
            fontName,
            fontSize,
            wAliment = "center",
            bBold = false
            ) {
            this.sheet.getCell(cellName).value = msg;
            this.sheet.getCell(cellName).alignment = {
                vertical: "middle",
                horizontal: wAliment,
                wrapText: true,
            };
            this.sheet.getCell(cellName).font = {
                name: fontName,
                size: fontSize,
                bold: bBold,
            };
        },
        setRow(
            row,
            fontName,
            fontSize,
            wAliment = "center",
            bBold = false,
        ) {
            let flightList = [];

            for (let col = 0; col < this.pageMaxCol; col++) {
                flightList.push(this.mySpreadsheet.getValueFromCoords(col, row - 1));
            }

            this.sheet.getRow(row).values = flightList;

            this.sheet.getRow(row).alignment = {
                vertical: "middle",
                horizontal: wAliment,
                wrapText: true,
            };
            this.sheet.getRow(row).font = {
                name: fontName,
                size: fontSize,
                bold: bBold,
            };
        },        
        setFormula(
            cellName,
            msg,
            fontName,
            fontSize,
            wAliment = "center",
            bBold = false
            ) {
                this.sheet.getCell(cellName).value = {
                    formula: msg
                }
                this.sheet.getCell(cellName).alignment = {
                    vertical: "middle",
                    horizontal: wAliment,
                    wrapText: true,
                };
                this.sheet.getCell(cellName).font = {
                    name: fontName,
                    size: fontSize,
                    bold: bBold,
                };
        },
        drawAllBorderLine(startCell, endCell, cellStyle) {
            // A12 -> "A" -> ascii A = 65
            var startASCIINum = startCell.toUpperCase().charCodeAt(0);
            // C24 -> "C" -> ascii C = 67
            var endASCIINum = endCell.toUpperCase().charCodeAt(0);

            // A12 -> 12
            var startNum = Number(startCell.substr(1));
            // C24 -> 24
            var endNum = Number(endCell.substr(1));

            for (var i = startASCIINum; i <= endASCIINum; i++) {
                for (var j = startNum; j <= endNum; j++) {
                    var targetCell = String.fromCharCode([i]) + j;
                    this.drawBorderLine(targetCell, cellStyle);
                }
            }
        },
        drawBorderLine(targetCell, flag) {
            this.sheet.getCell(targetCell).border = this.getBorderStyle(flag);
        },
        getBorderStyle(flag) {
            const myBorder = {
                top: { style: "" },
                left: { style: "" },
                right: { style: "" },
                bottom: { style: "" },
            };

            if (Number(flag & this.constants.TOP_LINE) == this.constants.TOP_LINE) {
                myBorder.top.style = "thin";
            }
            if (Number(flag & this.constants.LEFT_LINE) == this.constants.LEFT_LINE) {
                myBorder.left.style = "thin";
            }
            if (Number(flag & this.constants.RIGHT_LINE) == this.constants.RIGHT_LINE) {
                myBorder.right.style = "thin";
            }
            if (Number(flag & this.constants.BOTTOM_LINE) == this.constants.BOTTOM_LINE) {
                myBorder.bottom.style = "thin";
            }
            return myBorder;
        },
        setPageDrawBorder(totalPageCount) {
            for (let row = 0; row < totalPageCount; row++) {
                this.drawBorderLine('A' + (2 + 29 * row).toString(), this.constants.TOP_LINE | this.constants.LEFT_LINE | this.constants.RIGHT_LINE);
                this.drawBorderLine('A' + (3 + 29 * row).toString(), this.constants.LEFT_LINE);
                this.drawBorderLine('R' + (3 + 29 * row).toString(), this.constants.RIGHT_LINE);
                this.drawBorderLine('A' + (4 + 29 * row).toString(), this.constants.LEFT_LINE | this.constants.BOTTOM_LINE);
                this.drawBorderLine('C' + (4 + 29 * row).toString(), this.constants.BOTTOM_LINE);
                this.drawBorderLine('E' + (4 + 29 * row).toString(), this.constants.BOTTOM_LINE);
                this.drawBorderLine('G' + (4 + 29 * row).toString(), this.constants.BOTTOM_LINE);
                this.drawBorderLine('I' + (4 + 29 * row).toString(), this.constants.BOTTOM_LINE);
                this.drawBorderLine('M' + (4 + 29 * row).toString(), this.constants.BOTTOM_LINE);
                this.drawBorderLine('O' + (4 + 29 * row).toString(), this.constants.BOTTOM_LINE);
                this.drawBorderLine('Q' + (4 + 29 * row).toString(), this.constants.RIGHT_LINE | this.constants.BOTTOM_LINE);
                this.drawAllBorderLine('A' + (5 + 29 * row).toString(), 'C' + (5 + 29 * row).toString(), this.constants.ALL_LINE);
                this.drawAllBorderLine('I' + (5 + 29 * row).toString(), 'K' + (5 + 29 * row).toString(), this.constants.ALL_LINE);
                this.drawBorderLine('O' + (5 + 29 * row).toString(), this.constants.ALL_LINE);
                this.drawBorderLine('P' + (5 + 29 * row).toString(), this.constants.ALL_LINE);
                this.drawAllBorderLine('C' + (9 + 29 * row).toString(), 'H' + (11 + 29 * row).toString(), this.constants.RIGHT_LINE);
                this.drawAllBorderLine('K' + (9 + 29 * row).toString(), 'M' + (9 + 29 * row).toString(), this.constants.RIGHT_LINE);
                this.drawAllBorderLine('P' + (9 + 29 * row).toString(), 'R' + (9 + 29 * row).toString(), this.constants.RIGHT_LINE);
                this.drawAllBorderLine('A' + (12 + 29 * row).toString(), 'R' + (24 + 29 * row).toString(), this.constants.ALL_LINE);
                this.drawBorderLine('A' + (25 + 29 * row).toString(), this.constants.LEFT_LINE | this.constants.RIGHT_LINE);
                this.drawBorderLine('A' + (27 + 29 * row).toString(), this.constants.LEFT_LINE);
                this.drawBorderLine('M' + (27 + 29 * row).toString(), this.constants.RIGHT_LINE);
                this.drawBorderLine('A' + (28 + 29 * row).toString(), this.constants.LEFT_LINE);
                this.drawBorderLine('R' + (28 + 29 * row).toString(), this.constants.RIGHT_LINE);
                this.drawBorderLine('A' + (29 + 29 * row).toString(), this.constants.LEFT_LINE | this.constants.BOTTOM_LINE);
                this.drawAllBorderLine('B' + (29 + 29 * row).toString(), 'Q' + (29 + 29 * row).toString(), this.constants.BOTTOM_LINE);
                this.drawBorderLine('R' + (29 + 29 * row).toString(), this.constants.RIGHT_LINE | this.constants.BOTTOM_LINE);
            }
        }        
    }    
}
</script>