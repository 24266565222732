export default [
    {
        label: 'No.',
        field: 'orderNo',
        sort: true
    },
    {
        label: 'Remote ID',
        field: 'deviceId',
        sort: true
    },
    {
        label: '종류',
        field: 'category',
        sort: true
    },
    {
        label: '형식',
        field: 'type',
        sort: true
    },
    {
        label: '신고번호',
        field: 'no',
        sort: true
    },
    {
        label: '최종인증검사일',
        field: 'date',
        sort: true
    },
    {
        label: '자체중량(kg)',
        field: 'weight',
        sort: true
    },
    {
        label: '최대이륙중량(kg)',
        field: 'mtow',
        sort: true
    },
    {
        label: '',
        field: 'action',
        sort: false
    }
];
